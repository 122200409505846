import React from 'react';
import styled from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export type SwitchProps = {
  isChecked: boolean;
  callback: () => void;
  title?: string;
};

const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    `
      justify-content: space-between;
    `,
  )}
`;

const SwitchTitle = styled.p``;

const SwitchWrapper = styled.div`
  width: 45px;
`;

const SwitchInput = styled.input`
  display: none;

  &:checked {
    & ~ label {
      &::before {
        opacity: 1;
      }

      &::after {
        left: 20px;
        background: white;
      }
    }
  }
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  background: #e5e8ee;
  position: relative;
  cursor: pointer;
  border-radius: 1.6rem;

  &::after {
    content: '';
    position: absolute;
    left: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.medium};
    transition: all 0.4s ease;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: ${(props) => props.theme.palette.green};
    opacity: 0;
    transition: all 0.4s ease;
  }
`;

export function Switch({ isChecked, callback, title }: SwitchProps) {
  const handleChange = () => {
    callback();
  };

  return (
    <SwitchContainer>
      {title && <SwitchTitle>{title}</SwitchTitle>}
      <SwitchWrapper>
        <SwitchInput id="switch-1" type="checkbox" onChange={handleChange} checked={isChecked} />
        <SwitchLabel htmlFor="switch-1" />
      </SwitchWrapper>
    </SwitchContainer>
  );
}
