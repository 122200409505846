import React from 'react';
import styled from 'styled-components';

import { GenericItem } from '@marty-js/api-sdk/types';
import { PostItemCompact } from '@marty-js/design/src/molecules/post-item-compact';
import { Switch } from '@marty-js/design/src/atoms/switch';
import { formatDate, getHistoryDate } from '../atoms/date/utils';
import { Ad } from '../ads/ad';

type SwitchCompactProps = {
  callback: () => void;
  isCompact: boolean;
};

const SeperatorDate = styled.div`
  background: ${(props) => props.theme.colors.background};
  text-align: center;
  position: relative;
  padding: var(--spacer-sm-fluid) 0;
  margin: -2px 0;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: ${(props) => (props.theme.isDark ? 'white' : '#E5E8EE')};
  }
`;

const SeperatorDateLabel = styled.span`
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => (props.theme.isDark ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)')};
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 var(--spacer-s);
  position: relative;
  z-index: 1;
`;

const SwitchNews = styled.div`
  margin: 0 auto var(--spacer);
`;

export const generateCompactItem = (
  genericItems: GenericItem[],
  genericItem: GenericItem,
  index: number,
  t: any,
  condition?: boolean,
  mobilePos?: number,
) => {
  const link = {
    href: genericItem.url,
  };

  const date =
    genericItem?.updatedAt > genericItem?.republishedAt ? genericItem?.updatedAt : genericItem?.republishedAt;

  const previousDate = index > 0 ? new Date(genericItems[index - 1].republishedAt) : null;
  const currentDate = new Date(genericItem.republishedAt);
  const label = getHistoryDate(currentDate, previousDate, t('locale'));

  return (
    <>
      {condition && <Ad className={`Mobile_Pos${mobilePos}`} mobileOnly noBackground />}
      {label && (
        <SeperatorDate>
          <SeperatorDateLabel>{label}</SeperatorDateLabel>
        </SeperatorDate>
      )}
      <PostItemCompact
        id={genericItem.id}
        key={genericItem.id}
        title={genericItem.title}
        commentCount={genericItem.commentsNb}
        image={{ imageId: genericItem.imageId, alt: genericItem.title }}
        date={formatDate(date, 'HH:mm', t('locale'))}
        authors={genericItem.authors}
        link={link}
      />
    </>
  );
};

export const SwitchCompact = ({ isCompact, callback }: SwitchCompactProps) => {
  return (
    <SwitchNews>
      <Switch isChecked={isCompact} callback={callback} title="Flux condensé" />
    </SwitchNews>
  );
};
