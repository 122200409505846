import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentCount?: number;
  tag?: string;
  authors?: AuthorProp[];
}

const Post = styled.div`
  align-items: flex-start;
  border-top: solid 1px ${(props) => (props.theme.isDark ? 'white' : '#E5E8EE')};
  display: flex;
  gap: 15px;
  flex: 1 1 auto;
  justify-content: stretch;
  text-decoration: none;
  padding: var(--spacer-fluid) 0 var(--spacer-fluid);

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: row;
    `,
  )}
`;

const LeftSide = styled.div`
  font-size: 14px;
  min-width: var(--spacer-m);
  padding: 2px 0 0 0;
  position: relative;
  width: var(--spacer-m);
  line-height: 1.62;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      padding: 4px 0 0 0;
      font-size: 16px;
    `,
  )}
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.foreground};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.62;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 22px;
    `,
  )}
`;

const PublishDate = styled.span``;

export const PostItemCompact: any = (props: PostItemProps) => {
  const { title, date, link } = props;

  const LinkComponent = useLinkComponent();

  return (
    <LinkComponent {...link}>
      <Post>
        <LeftSide>
          <PublishDate data-testid="meta-date">{date}</PublishDate>
        </LeftSide>
        <Title data-testid="meta-label">{title}</Title>
      </Post>
    </LinkComponent>
  );
};
